import * as React from "react";

export const toSearchString = (searchParams: any = {}) => {
  return Object.keys(searchParams).map(key => `${key}=${encodeURIComponent(searchParams[key])}` ).join("&");
};

export const createMailtoLink = (email: string, headers?: object) => {
  let link = `mailto:${email}`;
  if (headers) {
    link += `?${toSearchString(headers)}`;
  }
  return link;
};

export interface Props {
  email: string;
  headers?: object;
  obfuscate?: boolean;
  [propName: string]: any;
}

class Mailto extends React.PureComponent<Props> {

  public static defaultProps = {
    obfuscate: false,
  };

  public render() {
    return this.props.obfuscate ?
      this.renderObfuscatedLink() :
      this.renderLink();
  }

  protected renderLink() {
    const { email, obfuscate, headers, children, ...others } = this.props;
    return (
      <a href={createMailtoLink(email, headers)} {...others}>
        {children}
      </a>
    );
  }

  protected renderObfuscatedLink() {
    const { email, obfuscate, headers, children, ...others } = this.props;
    return (
      <a onClick={this.handleClick.bind(this)} href="mailto:obfuscated" {...others}>
        {children}
      </a>
    );
  }

  protected handleClick(event: React.MouseEvent) {
    event.preventDefault();
    const { email, headers } = this.props;
    window.location.href = createMailtoLink(email, headers);
  }

}

export default Mailto;
