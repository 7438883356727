import { graphql } from "gatsby";
import * as React from "react";

import { default as HomePage, Props} from "../components/HomePage";

const HomePageTpl: React.SFC<Props> = ({ data }) => (<HomePage data={data} />);

export default HomePageTpl;

export const query = graphql`
  query HomePageQuery($id: String!) {
    site {
      siteMetadata {
        email
      }
    }
    bio: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        status
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___published] },
      filter: { frontmatter: { templateKey: { eq: "blog-post" } }},
      limit: 3,
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            author
            lang
            description
            templateKey
            published(formatString: "MMMM DD, YYYY")
            publishedDate: published(formatString: "YYYY-MM-DD")
            tags
          }
        }
      }
    }
  }
`;
